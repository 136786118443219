h1 {
  font-size: 3em;
  font-family: "Helvetica Neue", sans-serif;
}

h2 {
  font-size: 2.5em;
  font-family: "Georgia", serif;
}

.hero,
.features-overview,
.about-us,
.how-it-works,
.benefits,
.testimonials,
.major-pages,
.faq {
  width: 100vw;
  margin: 0;
  padding: 20px 0;
}

.hero img {
  border-radius: 15px;
}

.topbar {
  position: relative;
  width: calc(100% - 40px);
  background-color: transparent;
  padding: 10px 20px;
}

.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.logo-link {
  flex-shrink: 0;
}

.logo {
  height: 50px;
  margin-right: 20px;
}
.icon {
  height: 100px;
}

.menu-items {
  display: flex;
  gap: 20px;
}

.menu-button {
  background: none;
  border: none;
  color: inherit;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-button-container {
  position: relative;
}

.buttons {
  display: flex;
  gap: 20px;
  flex-shrink: 0;
}

.button {
  border-radius: 20px;
  padding: 10px 10px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.button.contained {
  background-color: #001e80;
  color: #fff;
  border: none;
}

.button.contained:hover {
  background-color: #f0f0f0;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 10px;
  width: auto;
  height: auto;
}

.menu-icon svg {
  width: 24px;
  height: 24px;
}

.menu-icon:hover {
  color: grey;
  background-color: #f0f0f0;
}

.drawer {
  position: fixed;
  top: 0;
  right: -100vw;
  width: calc(100% - 60px);
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}

.drawer.open {
  transform: translateX(-100vw);
  opacity: 1;
  z-index: 9999;
}

.drawer-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.close-icon {
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: inherit;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.close-icon:hover {
  color: grey;
  background-color: #f0f0f0;
}

.drawer-menu {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  text-align: center;
}

.drawer-menu li {
  margin-bottom: 30px;
}

/* Medium header styling */
@media (min-width: 700px) and (max-width: 1315px) {
  .toolbar {
    justify-content: space-between;
  }

  .menu-items {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .buttons {
    display: flex;
    margin-left: auto;
  }
}

/* Small header styling */
@media (max-width: 700px) {
  .toolbar {
    justify-content: space-between;
  }

  .logo {
    margin-right: 0;
  }

  .menu-icon {
    display: block;
  }

  .menu-items {
    display: none;
  }

  .buttons {
    display: none;
  }
}

/* Large header styling */
@media (min-width: 1315px) {
  .menu-items {
    display: flex;
  }

  .menu-icon {
    display: none;
  }

  .buttons {
    display: flex;
  }
}

.topbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #001e80, #2651d1);
}

/* General dropdown styling */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 10px;
  border-radius: 8px;
}

/* Features dropdown specific styling */
.dropdown-features {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  gap: 10px;
}

/* Policies and Guides dropdown specific styling */
.dropdown-policies-guides {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  gap: 20px;
}

/* About Us dropdown specific styling */
.dropdown-about {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.dropdown-column {
  padding: 10px;
  min-width: 150px;
}

.dropdown-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.dropdown-item {
  background: none;
  border: none;
  color: inherit;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-item:hover {
  color: #001e80;
}

@media (min-width: 600px) {
  .dropdown-column {
    min-width: 200px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(100vw);
}

.text-container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
.small-text-container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .main-content > .text-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .main-content > .text-container {
    padding: 10px;
  }
}

/* Footer Section */
.footer {
  background: #f8f8f8;
  padding: 50px 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 2;
  margin: 20px;
  min-width: 200px;
  text-align: left;
}

.footer-section.support {
  text-align: center;
}

.footer-section.contact {
  margin-top: 20px;
  text-align: center;
}

.footer-section.quick-links {
  flex: 1;
}

.footer-section.menu-columns {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer-subsection {
  margin-bottom: 20px;
}

.footer-section h4 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.footer-section p {
  margin-bottom: 20px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin: 10px 0;
}

.footer-section a {
  color: #333;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.icon {
  width: 100px;
  margin-bottom: 10px;
}

.button-container {
  margin-top: 20px;
}

.footer-bottom {
  margin-top: 50px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.footer-bottom .logo {
  width: 150px;
  margin-bottom: 10px;
}

.footer-bottom p {
  margin: 0;
  color: #666;
}
@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }

  .footer-section ul {
    list-style: none;
    padding: 0;
  }

  .footer-section li {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-section a {
    display: inline-block;
    text-align: center;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom p {
    text-align: center;
  }
}
.contact-link {
  color: #001e80;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}
.cta-section {
  background: linear-gradient(to right, #001e80, #2651d1);
  color: #fff;
  text-align: center;
  padding: 60px 20px;
  margin-top: 40px;
}

.cta-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
}

.button.outlined {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.button.outlined:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button.contained {
  background-color: #fff;
  color: #001e80;
  border: none;
}

.top-bar-button {
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.top-bar-button.outlined {
  background-color: #001e80;
  color: #fff;
}

.top-bar-button.outlined:hover {
  background-color: #224abe;
}

.top-bar-button.contained {
  background-color: #fff;
  color: #001e80;
}

.top-bar-button.contained:hover {
  background-color: #f0f0f0;
}

.contact-form-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  background: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 40px auto;
  max-width: 1200px;
}

.contact-form-content {
  flex: 1;
  margin-right: 40px;
}

.contact-form-content h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.contact-form-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.contact-form-content a {
  color: #001e80;
  text-decoration: none;
  font-weight: bold;
}

.contact-form-content a:hover {
  text-decoration: underline;
}

.contact-form {
  flex: 1;
  width: 100%;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.form-input,
.form-textarea {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: #fff;
  transition: all 0.3s ease;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-input:focus,
.form-textarea:focus {
  border-color: #001e80;
  box-shadow: 0 0 8px rgba(0, 30, 128, 0.2);
}

.form-textarea {
  height: 150px;
  resize: vertical;
}

.form-button {
  padding: 15px 20px;
  background-color: #001e80;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #224abe;
}

.form-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.status-message {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
}

.success {
  color: green;
  border: 1px solid green;
}

.error {
  color: red;
  border: 1px solid red;
}

.dot-1,
.dot-2,
.dot-3 {
  animation: blink 1s infinite step-start;
}

.dot-2 {
  animation-delay: 0.2s;
}

.dot-3 {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-form-content {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .contact-form {
    width: 100%;
  }
}

.our-story-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
  text-align: center;
}

.our-story-section {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-in-out;
}

.our-story-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.our-story-subtitle {
  color: #001e80;
  margin-bottom: 20px;
  text-align: center;
}

.our-story-subtitle {
  font-size: 2em;
  margin-top: 40px;
}

.our-story-text {
  font-size: 1.5em;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: center;
}

.our-story-intro,
.our-story-challenges,
.our-story-vision,
.our-story-commitment {
  background: linear-gradient(to right, #f8f9fa, #e0e7ff);
  color: #001e80;
}

.our-story-origin,
.our-story-development,
.our-story-unique,
.our-story-name {
  background: linear-gradient(to right, #e0e7ff, #f8f9fa);
  color: #001e80;
}

.our-story-intro,
.our-story-origin,
.our-story-challenges,
.our-story-development,
.our-story-vision,
.our-story-unique,
.our-story-commitment,
.our-story-name {
  padding: 60px 20px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .our-story-container {
    padding: 10px 20px;
  }

  .our-story-subtitle {
    font-size: 1.8em;
  }

  .our-story-text {
    font-size: 1.2em;
  }

  .our-story-intro,
  .our-story-origin,
  .our-story-challenges,
  .our-story-development,
  .our-story-vision,
  .our-story-unique,
  .our-story-commitment,
  .our-story-name {
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
}
.info-card {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info-card-title {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 10px;
}

.info-card-text {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
}

.info-card-link {
  color: #001e80;
  text-decoration: none;
}

.info-card-link:hover {
  text-decoration: underline;
}
.pricing-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}

.pricing-header {
  margin-bottom: 40px;
}

.pricing-section {
  padding: 60px 20px;
  margin-bottom: 40px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.paymentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  flex: 1;
  min-width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
  color: #001e80;
}

.button-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
}
.paymentCard:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.amount {
  font-size: 3em;
  margin: 20px auto;
  border: 8px solid white;
  padding: 10px;
  border-radius: 8px;
  width: 150px;
  text-align: center;
}

.free-plan-card {
  background: linear-gradient(to right, #ec3443, #d37e07);
  color: #fff;
}

.monthly-plan-card {
  background: linear-gradient(to right, #6a11cb, #b92b27);
  color: #fff;
}

.yearly-plan-card {
  background: linear-gradient(to right, #2575fc, #6a11cb);
  color: #fff;
  position: relative;
}

.donation-card {
  background: linear-gradient(to right, #12c2e9, #af3de8, #f64f59);
  color: #fff;
  margin-top: 20px;
  padding: 50px 20px;
  text-align: center;
}

.discountTag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fc4a1a;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.9em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Mobile optimization */
@media (max-width: 768px) {
  .pricing-container {
    padding: 20px 10px;
  }

  .pricing-subtitle {
    font-size: 1.8em;
  }

  .pricing-text,
  .pricing-list {
    font-size: 1.2em;
  }

  .card-container {
    flex-direction: column;
  }
  .paymentCard {
    padding: 20px;
    max-width: 85%;
    margin: 0 auto; /* Centering the card */
    position: relative;
  }

  .donation-card {
    padding: 10px; /* Adding padding from the sides on mobile */
    margin: 0 auto; /* Centering the donation card */
  }
}
.security-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
}

.title {
  font-size: 3em;
  color: #001e80;
  margin-bottom: 20px;
  text-align: center;
}

.security-intro {
  font-size: 1.5em;
  line-height: 1.8;
  margin-bottom: 40px;
  text-align: center;
}

.security-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.security-card {
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.security-card h3 {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 10px;
}

.security-card p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #333;
}

.security-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

/* Mobile optimization */
@media (max-width: 768px) {
  .security-container {
    padding: 20px 10px;
  }

  .security-title {
    font-size: 2.5em;
  }

  .security-intro {
    font-size: 1.2em;
  }

  .security-card h3 {
    font-size: 1.8em;
  }

  .security-card p {
    font-size: 1.1em;
  }
}
.contact-page {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
  text-align: center;
}

.document-manager {
  font-family: "Roboto", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
}

.document-manager h1 {
  font-size: 3em;
  color: #001e80;
  margin-bottom: 20px;
  text-align: center;
}

.document-manager nav {
  text-align: center;
  margin-bottom: 40px;
}

.document-manager nav a {
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.document-manager nav a:hover {
  color: #0056b3;
}

.document-manager section {
  margin-bottom: 40px;
}

.document-manager h2 {
  font-size: 2.5em;
  color: #001e80;
  margin-bottom: 20px;
  text-align: left;
}

.document-manager ul {
  list-style: none;
  padding: 0;
}

.document-manager li {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.document-manager li:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.document-manager li a {
  color: #001e80;
  text-decoration: none;
  display: block;
}

.document-manager li a strong {
  display: block;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.document-manager li a p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .document-manager {
    padding: 20px 10px;
  }

  .document-manager h1 {
    font-size: 2.5em;
  }

  .document-manager h2 {
    font-size: 2em;
  }

  .document-manager nav a {
    font-size: 1em;
  }

  .document-manager li a strong {
    font-size: 1.3em;
  }

  .document-manager li a p {
    font-size: 1em;
  }
}
.document-detail {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  color: #333;
}

.document-detail h1 {
  font-size: 2.5em;
  color: #001e80;
  margin-bottom: 20px;
}

.document-detail p {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 20px;
}

.document-detail iframe {
  border: none;
  margin-bottom: 20px;
  width: 100%;
  height: 1200px;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .document-detail {
    padding: 20px 10px;
  }

  .document-detail h1 {
    font-size: 2em;
  }

  .document-detail p,
  .document-detail iframe {
    font-size: 1em;
  }
}

.feature-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
  text-align: center;
}

.feature-header {
  margin-bottom: 40px;
}

.feature-header h2 {
  font-size: 2em;
  color: #555;
  margin-bottom: 20px;
}

.feature-header img,
.rounded-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.feature-overview,
.feature-description,
.feature-cards,
.feature-footer {
  margin-bottom: 40px;
}

.feature-overview h3,
.feature-description h3,
.feature-footer h3 {
  font-size: 1.5em;
  color: #001e80;
  margin-bottom: 20px;
}

.feature-overview p,
.feature-description p,
.feature-cards p,
.faq p {
  font-size: 1.5em;
}

.feature-description p {
  text-align: left;
}

.cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
}

.faqs-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Custom FAQs Section */
.feature-faqs {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-faqs h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #001e80;
}

.feature-faqs .faq {
  margin-bottom: 20px;
}

.feature-faqs .faq h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.feature-faqs .faq p {
  font-size: 1em;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

.feature-faqs .faq + .faq {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.card h3 {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 10px;
}

.card ul {
  list-style: none;
  padding: 0;
}

.card ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.card ul li i {
  color: #001e80;
  margin-right: 10px;
}

.testimonial {
  margin-bottom: 20px;
  font-style: italic;
}

.faq h4 {
  font-size: 1.8em;
  color: #333;
}

.limited-height {
  max-height: 600px;
}

.feature-footer p {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .feature-container {
    padding: 20px 10px;
  }

  .feature-header h1 {
    font-size: 2.5em;
  }

  .feature-header h2,
  .feature-overview h3,
  .feature-description h3,
  .feature-footer h3,
  .card h3 {
    font-size: 1.5em;
  }

  .cards-grid {
    grid-template-columns: 1fr;
  }
}
/* General Feature Template Styles */
.feature-template-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
  text-align: center;
}

.feature-template-header {
  margin-bottom: 40px;
}

.feature-template-header h2 {
  font-size: 2em;
  color: #555;
  margin-bottom: 20px;
}

.feature-template-header img,
.rounded-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.feature-template-overview,
.feature-template-description,
.feature-template-cards,
.feature-template-footer {
  margin-bottom: 40px;
}

.feature-template-overview h3,
.feature-template-description h3,
.feature-template-footer h3 {
  font-size: 1.5em;
  color: #001e80;
  margin-bottom: 20px;
}

.feature-template-overview p,
.feature-template-description p,
.feature-template-cards p,
.feature-template-faq p {
  font-size: 1.5em;
}

.feature-template-description p {
  text-align: left;
}

/* Feature Template Cards */
.feature-template-cards-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.feature-template-card,
.feature-template-faqs-container,
.feature-template-testimonial-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  margin: 10px;
  max-width: 800px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-template-card:hover,
.feature-template-faqs-container:hover,
.feature-template-testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-template-card h3,
.feature-template-faqs-container h3,
.feature-template-testimonial-card h3 {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 10px;
  margin-top: 10px;
}

.feature-template-card ul {
  list-style: none;
  padding: 0;
}

.feature-template-card ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-size: 1.5em;
}

.feature-template-card ul li i {
  color: #001e80;
  margin-right: 10px;
}
/* Feature How It Works Section */
.feature-template-cards .flow-container {
  display: grid;
  grid-template-columns: repeat(
    8,
    1fr
  ); /* Adjust based on the number of steps */
  align-items: center;
  text-align: center;
  gap: 0px;
}

.how-to-card {
  max-width: 1200px !important;
  margin: 0 auto; /* Center the card horizontally */
}

.feature-template-cards .flow-step-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2; /* Each step takes 2 columns */
}

.feature-template-cards .flow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.feature-template-cards .step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-template-cards .flow-icon {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 10px;
}

.feature-template-cards .arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 1; /* Arrow takes 1 column */
}

.feature-template-cards .arrow-icon {
  font-size: 1.5em;
  color: #001e80;
}

.feature-template-cards .flow-step h3 {
  font-size: 1.5em;
  color: #001e80;
  margin: 0;
  margin-bottom: 10px;
}

.arrow-desktop {
  display: inline;
}

.arrow-mobile {
  display: none;
}

@media (max-width: 768px) {
  .feature-template-cards .flow-container {
    flex-direction: column;
    display: flex;
  }

  .arrow-desktop {
    display: none;
  }

  .arrow-mobile {
    display: inline;
    font-size: 2em;
  }
}

.feature-template-testimonials-section {
  margin: 40px 0;
  text-align: center;
}

.feature-template-testimonials-section h2 {
  font-size: 2em;
  color: #001e80;
  margin-bottom: 20px;
}

.feature-template-testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.feature-template-testimonial-card p {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.feature-template-testimonial-card.active {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Custom FAQs Section */
.feature-template-faqs-section {
  display: flex;
  justify-content: center;

  text-align: center;
  width: 100%;
}

.feature-template-faqs-container {
  max-width: 800px;
  width: 100%;
  align-items: center;
  background: #f4f6f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-template-faqs-container h3 {
  font-size: 2em;
  color: #001e80;
  text-align: center;
  margin-bottom: 20px;
}

.feature-template-faq {
  margin-bottom: 20px;
}

.feature-template-faq h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.feature-template-faq p {
  font-size: 1.2em;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

.feature-template-faq + .feature-template-faq {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.feature-template-footer p {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .feature-template-container {
    padding: 20px 10px;
  }

  .feature-template-header h1 {
    font-size: 2.5em;
  }

  .feature-template-header h2,
  .feature-template-overview h3,
  .feature-template-description h3,
  .feature-template-footer h3,
  .feature-template-card h3 {
    font-size: 1.5em;
  }

  .feature-template-cards-grid {
    flex-direction: column;
  }

  .feature-template-card {
    max-width: 100%;
  }
}

/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #001e80;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-container p {
  margin-top: 16px;
  font-size: 1.2em;
  color: #333;
}
.blog-container {
  font-family: "Roboto", sans-serif;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

.blog-header h1 {
  font-size: 2.5em;
  color: #001e80;
  margin-bottom: 20px;
}

.blog-header p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 40px;
}

.blog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.under-construction-image {
  max-width: 10%;
  height: auto;
  margin-bottom: 20px;
}

.blog-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.features-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  z-index: 1;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.feature-card {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  width: calc(40% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: #333;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: transform 0.2s;
  z-index: 1;
  overflow: hidden;
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to bottom right, #2651d1, #001e80);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 1;
}

.feature-card h2 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #001e80;
  z-index: 1;
}

.feature-card p {
  margin-bottom: 20px;
  font-size: 1em;
  line-height: 1.4;
  z-index: 1;
}

.feature-link {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #aaa;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1em;
  z-index: 1;
}

.feedback-section {
  margin-top: 40px;
  padding: 40px;
  border-top: 1px solid #ccc;
  text-align: center;
  width: calc(100% - 80px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  background-clip: padding-box;
  border-radius: 8px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to right, #001e80, #87ceeb);
  z-index: 1;
}

.feedback-section h2 {
  font-size: 2em;
  color: #001e80;
}

.feedback-section p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .feature-card {
    width: calc(100% - 40px);
    margin: 10px 20px;
  }

  .feedback-section {
    width: calc(100% - 120px);
    margin: 10px 20px;
  }
}
/* Global Styles */

.button-container {
  display: flex;
  gap: 10px;
}

/* Hero Section */
.hero {
  background: linear-gradient(to right, #d5d5d5, #f9f9f9);
  color: #001e80;
  text-align: center;
  padding: 50px 20px;
}

.hero h1 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 3em;
  color: #001e80;
  margin-bottom: 10px;
  font-weight: 300;
}

.hero h2 {
  font-family: "Georgia", serif;
  font-size: 2em;
  color: #000;
  font-weight: 300;
}

.hero img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .hero {
    background: linear-gradient(to right, #d5d5d5, #f9f9f9);
    color: #001e80;
    text-align: center;
  }

  .hero h1 {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 2em;
    color: #001e80;
    margin-bottom: 10px;
    font-weight: 300;
  }

  .hero h2 {
    font-family: "Georgia", serif;
    font-size: 1.5em;
    color: #000;
    font-weight: 300;
  }

  .hero img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
}
/* About Us Section */
.about-us {
  background: linear-gradient(to right, #d5d5d5, #f9f9f9),
    linear-gradient(to bottom, rgba(249, 249, 249, 0), #ffffff 80%);
  background-blend-mode: overlay;
  color: #333;
  padding: 50px 20px;
  text-align: center;
}

.about-us-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-us-text {
  font-size: 1.25em;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Features Overview Section */
.features-overview {
  background: #fff;
  color: #333;
  padding: 50px 20px;
}

.features-overview h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 40px;
}

.feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.feature-text {
  max-width: 500px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.feature-icon {
  background: linear-gradient(to right, #001e80, #224abe);
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.5em;
  align-items: center;
  margin-right: 10px;
}

.feature img {
  width: 400px;
  height: 400px;
  display: block;
}

.feature:nth-child(even) {
  flex-direction: row-reverse;
}

.feature h3 {
  color: #001e80;
  font-size: 1.8em;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature p {
  margin-bottom: 20px;
  text-align: center;
}

.feature button {
  margin-right: 10px;
}
@media (max-width: 768px) {
  .feature {
    flex-direction: column;
    text-align: center;
  }

  .feature-text {
    padding-right: 0;
    margin-bottom: 20px;
    height: auto;
  }

  .floating-image-container {
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .floating-image {
    max-width: 80%;
    height: auto;
  }
}
.highlight-container {
  text-align: center;
  padding: 30px;
  margin-top: 40px;
  background-color: #f1f5f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.highlight-text {
  font-family: "Helvetica Neue", sans-serif;
  color: #001e80;
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.highlight-description {
  font-family: "Helvetica Neue", sans-serif;
  color: #333;
  font-weight: 400;
  font-size: 1.2em;
  margin: 0 auto;
  max-width: 600px;
  line-height: 1.6;
}

/* How It Works Section */
.how-it-works {
  background: linear-gradient(to bottom, #001e80, #87ceeb);
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.how-it-works .container {
  max-width: 1200px;
  margin: 0 auto;
}

.how-it-works h2 {
  margin-bottom: 30px;
  font-size: 2.5em;
}

.flow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 20px;
  text-align: center;
}

.flow-icon {
  font-size: 3em;
  margin-bottom: 10px;
}

.arrow-icon {
  font-size: 2em;
  margin: 0 20px;
  color: #fff;
}

@media (max-width: 768px) {
  .flow-container {
    flex-direction: column;
  }

  .flow-step {
    margin-bottom: 20px;
  }

  .arrow-icon {
    transform: rotate(90deg);
    margin: 20px 0;
  }
}

/* Benefits Section */
.benefits {
  background: linear-gradient(to bottom, #87ceeb 0%, #add8e6 50%, #b4e1f0 100%);
  color: #333;
  padding: 50px 20px;
  text-align: center;
}

.benefits .container {
  max-width: 1200px;
  margin: 0 auto;
}

.benefits h2 {
  margin-bottom: 30px;
  font-size: 2.5em;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.benefit-card {
  background: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 300px;
}

.benefit-icon {
  font-size: 3em;
  margin-bottom: 10px;
}

.benefit-card h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.benefit-card p {
  margin: 0;
}

/* Testimonials Section */
.testimonials {
  background: linear-gradient(
    to bottom,
    #b4e1f0 0%,
    #b4e1f0d8 20%,
    #b4e1f0 50%,
    #add8e6 80%,
    #add8e6 100%
  );
  color: #333;
  padding: 50px 20px;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 250px;
}

.testimonials h2 {
  margin: 0px;
}
.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.testimonial-card {
  position: absolute;
  display: none;
  font-style: italic;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  width: 28%;
  max-width: 300px;
  margin: 0 10px;
  height: auto;
  transition: opacity 1.5s ease, transform 1.5s ease, transform 1.5s ease,
    box-shadow 0.5s ease;
}

.testimonial-card.active {
  display: block;
  opacity: 1;
  transform: translateX(0) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-card.left,
.testimonial-card.right,
.testimonial-card.offscreen-left,
.testimonial-card.offscreen-right {
  display: block;
  opacity: 0.5;
  transform: translateX(0);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.testimonial-card.left {
  transform: translateX(-110%);
}

.testimonial-card.right {
  transform: translateX(110%);
}

.testimonial-card.offscreen-left {
  transform: translateX(220%);
  opacity: 0;
}

.testimonial-card.offscreen-right {
  transform: translateX(-220%);
  opacity: 0;
}

@media (max-width: 768px) {
  .testimonial-card {
    width: 80%;
    max-width: none;
    margin: 0 5px;
  }

  .testimonial-card.active {
    transform: translateX(0) scale(1.02);
  }

  .testimonial-card.left,
  .testimonial-card.right {
    transform: translateX(0);
  }

  .testimonial-card.left {
    transform: translateX(-105%);
  }

  .testimonial-card.right {
    transform: translateX(105%);
  }

  .testimonial-card.offscreen-left {
    transform: translateX(210%);
  }

  .testimonial-card.offscreen-right {
    transform: translateX(-210%);
  }
}

/* Major Pages Links Section */
.major-pages {
  background: linear-gradient(to bottom, #add8e6 0%, #d5d5d5 100%);
  color: #333;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.page-link {
  background: #ffffff;
  padding: 20px;
  margin: 10px;
  flex: 1 1 30%;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  height: 150px;
  max-width: 800px;
}

.page-link.left .text {
  text-align: left;
  padding-right: 50%;
  width: 100%;
}

.page-link.right .text {
  text-align: right;
  padding-left: 50%;
  width: 100%;
}

.page-link.left .opaque-icon {
  right: 0;
}

.page-link.right .opaque-icon {
  left: 0;
}

.page-link h3 {
  color: #001e80;
}

.page-link p {
  margin-bottom: 10px;
}

.opaque-icon {
  font-size: 200px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

/* FAQ Section */
.faq {
  background: linear-gradient(to bottom, #d5d5d5 0%, #ffffff 100%);
  color: #333;
  padding: 50px 20px;
  text-align: center;
}

.faq h2 {
  color: #001e80;
  font-size: 2em;
  margin-bottom: 20px;
}

.faq-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.faq-item {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.faq-item h3 {
  color: #001e80;
  font-size: 1.5em; /* Increased font size for questions */
  margin: 0;
  margin-bottom: 10px;
}

.faq-item p {
  font-size: 1em; /* Reduced font size for answers */
  color: #666;
  margin: 0;
}

@media (max-width: 768px) {
  .faq-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .faq-content {
    grid-template-columns: 1fr;
  }
  .faq-content h3 {
    font-size: 1em;
  }
  .faq-content p {
    font-size: 0.8em;
  }
}

/* Donation Section */
.donation {
  background: #fff;
  color: #333;
  padding: 50px 20px;
  text-align: center;
}

/* Lazy Loading Animation */
section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

section.lazy-loaded {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .major-pages {
    flex-direction: column;
  }

  .page-link {
    flex: 1 1 100%;
  }

  button {
    margin: 5px 0;
  }
}
.nav-icon {
  background: linear-gradient(to right, #001e80, #2651d1);
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-icon {
  background: linear-gradient(to right, #001e80, #224abe);
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 1em;
  align-items: center;
}
.feature-icon-large {
  background: linear-gradient(to right, #001e80, #224abe);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 50%;
  padding: 20px;
  font-size: 200px;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0.15;
  z-index: -1;
}
.feature-icon-header {
  background: linear-gradient(to right, #001e80, #224abe);
  top: 50%;
  left: 50%;
  color: white;
  border-radius: 50%;
  padding: 20px;
  font-size: 200px;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  opacity: 0.15;
}
.feature-header {
  position: relative;
  text-align: center;
}

.icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.feature-icon-header {
  color: white;
  border-radius: 50%;
  padding: 20px;
  font-size: 200px;
  width: 180px;
  height: 180px;
  opacity: 0.15;
}
.floating-image-container {
  perspective: 1000px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.floating-image {
  width: 100%;
  max-width: 300px;
  height: 250px;
  object-fit: cover;
  object-position: top;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.floating-image-left {
  transform: rotateY(10deg);
}

.floating-image-left:hover {
  transform: rotateY(-10deg) translateY(-10px);
}

.floating-image-right {
  transform: rotateY(-10deg);
}

.floating-image-right:hover {
  transform: rotateY(10deg) translateY(-10px);
}
@media (max-width: 768px) {
  .grow-wealth,
  .simplify-life {
    display: block;
    line-height: 1.2;
  }
}
